
import Markdown from 'markdown-to-jsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { MoreBlock } from '../common/MoreBlock';
import styles from './TermsOfUse.module.scss';

interface TermsOfUseProps {
  className?: string;
}

export const TermsOfUse: FC<TermsOfUseProps> = ({
  className
}) => {
  const { t } = useTranslation();

  return (
    <MoreBlock className={cn(styles.TermsOfUse, className)}>
      <Markdown>{t('orgInfo:termsOfUse')}</Markdown>
    </MoreBlock>
  );
}