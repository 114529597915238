import { FC, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './MoreBlock.module.scss';

interface MoreBlockProps {
  className?: string;
}

export const MoreBlock: FC<PropsWithChildren<MoreBlockProps>> = ({
  className,
  children
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState<HTMLDivElement | null>(null);
  const [expand, setExpand] = useState(false);

  return (
    <div className={className}>
      <div
        className={cn(styles.Expandable)}
        style={{
          height: expand ? `${content?.clientHeight ?? 0}px` : '0'
        }}>
        <div ref={setContent} className={styles.Content}>
          {children}
        </div>
      </div>
      <button
        type="button"
        className={styles.Toggle}
        onClick={() => setExpand(expand => !expand)}
      >
        {t(expand ? 'common:less' : 'common:more')}
      </button>
    </div>
  );
};
