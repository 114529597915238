import Markdown from 'markdown-to-jsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation } from 'react-router-dom';
import styles from './Result.module.scss';
import { ErrResultStateSchema } from './ResultState';

export const ResultFailure: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const result = ErrResultStateSchema.safeParse(location.state);

  if (!result.success) {
    return (
      <Navigate to="/" replace={true} />
    );
  }

  const state = result.data;

  return (
    <div className={styles.Container}>
      <Markdown>
        {t('payment:failureBody', {
          replace: {
            'order_id': state.orderId || '?',
            'message': state.message ?? ''
          }
        })}
      </Markdown>
      <div className={styles.Footer}>
        <Link to="/">{t('common:navHome')}</Link>
      </div>
    </div>
  );
};
