import { FC, useState } from 'react';
import cn from 'classnames';
import logo from '../assets/header-logo-min.png';
import { ReactComponent as MenuIcon } from '../assets/menu_btn.svg';
import styles from './Header.module.scss';
import { Menu } from './Menu';

interface HeaderProps {
  className?: string;
  onToggleMenu?: () => void;
}

export const Header: FC<HeaderProps> = ({
  className,
  onToggleMenu
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={cn(styles.Header, className)}>
      <header className={cn(styles.LeadingRow, { [styles.MenuOpen]: menuOpen })}>
        <img className={styles.HeaderLogo} src={logo} alt="Tribal Diving Online" />
        <h1 className={styles.HeaderTitle}>Tribal Diving Online</h1>
        <div className={styles.HeaderMenuBtn}>
          <button
            type="button"
            className={styles.MenuBtn}
            onClick={() => setMenuOpen(open => !open)}
          >
            <MenuIcon className={styles.MenuBtnIcon} />
          </button>
        </div>
      </header>
      <Menu
        className={cn(styles.Menu, { [styles.MenuOpen]: menuOpen })}
        onClose={() => setMenuOpen(false)}
      />
    </div>
  );
}
