import { FC } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ResultState } from './ResultState';

export const ResultCallback: FC = () => {
  const [searchParams] = useSearchParams();
  const state = deriveStateFromSearch(searchParams);

  return (
    <Navigate
      to={state.status === 'ok' ? '/result/success' : '/result/failure'}
      replace={true}
      state={state}
    />
  );
};

function deriveStateFromSearch(search: URLSearchParams): ResultState {
  const status = search.get('status') ?? 'err';
  const course = search.get('course') ?? undefined;
  const email = search.get('email') ?? undefined;
  const orderId = search.get('orderId') ?? undefined;

  if (status === 'err' || !course || !email || !orderId) {
    return {
      status: 'err',
      orderId,
      message: search.get('message') ?? 'Unexpected error. Call our support'
    };
  }

  return {
    status: 'ok',
    orderId,
    course,
    email
  };
}
