import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import { MoreBlock } from '../common/MoreBlock';
import styles from './Courses.module.scss';

interface CoursesProps {
  className?: string;
}

export const Courses: FC<CoursesProps> = ({
  className
}) => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState<CourseResponse[]>([]);

  useEffect(() => {
    const abort = new AbortController();
    async function loadCourses() {
      const res = await window.fetch(`${process.env.REACT_APP_API_URI}/courses`, {
        signal: abort.signal
      });
      const data: CoursesResponse = await res.json();
      setCourses(data.courses);
    }
    loadCourses();
    return () => {
      abort.abort();
    };
  }, []);

  return (
    <div className={className}>
      <div className={styles.CourseList}>
        {courses.map(course => (
          <div key={course.id} className={styles.CourseItem}>
            <img
              src={course.cover}
              alt={t(course.title)}
              className={styles.CourseCover}
            />
            <div className={styles.CourseLine}>
              <h2 className={styles.CourseTitle}>{t(course.title)}</h2>
              <div className={styles.CoursePriceLabel}>{course.price}</div>
              <a
                href={course.purchaseUrl}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  window.fbq?.('track', 'AddToCart', {
                    content_ids: [course.id],
                    content_name: t(course.title),
                    content_type: 'product'
                  });
                }}
                className={styles.CourseBuyBtn}
                title={t(`common:buyBtnLabel`)}
              >
                {t(`common:buyBtnLabel`)}
              </a>
            </div>
            {course.notes && (
              <div className={styles.CourseNotes}>
                <Markdown>{t(course.notes)}</Markdown>
              </div>
            )}
            <MoreBlock className={styles.CourseDescr}>
              <Markdown>
                {t(course.description)}
              </Markdown>
            </MoreBlock>
          </div>
        ))}
      </div>
    </div>
  );
}

interface CoursesResponse {
  courses: CourseResponse[];
}

interface CourseResponse {
  id: string;
  title: string;
  notes?: string;
  description: string;
  cover: string;
  purchaseUrl: string;
  price: string;
}
