import Markdown from 'markdown-to-jsx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation } from 'react-router-dom';
import styles from './Result.module.scss';
import { OkResultStateSchema } from './ResultState';

export const ResultSuccess: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const result = OkResultStateSchema.safeParse(location.state);
  const [course, setCourse] = useState<CourseResponse | undefined>(undefined);

  const courseId = result.success ? result.data.course : undefined;

  useEffect(() => {
    if (!courseId) return;
    const abort = new AbortController();
    loadCourseById(courseId, { signal: abort.signal })
      .then(setCourse)
      .catch(() => undefined);
    return () => {
      abort.abort();
    };
  }, [courseId]);

  if (!result.success) {
    return (
      <Navigate to="/" replace={true} />
    );
  }

  const state = result.data;

  return (
    <div className={styles.Container}>
      <Markdown>
        {t('payment:successfulBody', {
          replace: {
            'course_name': course ? t(course.title) : state.course,
            'recepient_email': state.email,
            'order_id': state.orderId || '?'
          }
        })}
      </Markdown>
      <div className={styles.Footer}>
        <Link to="/">{t('common:navHome')}</Link>
      </div>
    </div>
  );
};

interface CourseResponse {
  id: string;
  title: string;
}

async function loadCourseById(
  courseId: string,
  init?: RequestInit
): Promise<CourseResponse> {
  const res = await window.fetch(`${process.env.REACT_APP_API_URI}/courses/${courseId}`, {
    ...init,
    method: 'GET'
  });
  if (!res.ok) {
    throw new Error(`Cannot fetch course w/ id="${courseId}"`);
  }
  const course: CourseResponse = await res.json();
  return course;
}
