import { FC } from 'react';
import cn from 'classnames';
import styles from './Footer.module.scss';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';

interface FooterProps {
  className?: string;
}

export const Footer: FC<FooterProps> = ({
  className
}) => {
  const { t } = useTranslation();

  return (
    <footer className={cn(styles.Footer, className)}>
      <div className={styles.OrgInfo}>
        <Markdown>
          {t('orgInfo:info')}
        </Markdown>
      </div>
      <div className={styles.Copyright}>
        {t('common:copyright', { year: new Date().getFullYear() })}
      </div>
    </footer>
  );
};
