import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Courses } from '../content/Courses';
import { TermsOfUse } from '../content/TermsOfUse';
import styles from './Home.module.scss';

export const Home: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Home}>
      <div className={styles.Cover}>
        <div className={styles.CoverText}>
          {t('common:projectDescr')}
        </div>
      </div>
      <div className={styles.Content}>
        {/* Courses */}
        <h1 className={styles.SectionTitle}>
          {t('courses:sectionLabel')}
        </h1>
        <Courses />
        {/* Terms of Use */}
        <h1 className={styles.SectionTitle}>{t('menu:termsLabel')}</h1>
        <TermsOfUse />
      </div>
    </div>
  );
}
