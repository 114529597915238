import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import FetchBackend from 'i18next-fetch-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(FetchBackend)
  .init({
    ns: ['common', 'orgInfo', 'menu', 'courses', 'payment'],
    fallbackLng: {
      'en': ['uk'],
      'uk': ['en'],
      'ru': ['uk', 'en'],
      'default': ['en']
    },
    backend: {
      loadPath: process.env.REACT_APP_I18N_URI,
      // TODO: remove this workaround for loading multiple NSs in one file
      parse: (payload: string, loadUrl: string) => {
        const uri = new URL(loadUrl);
        const ns = uri.searchParams.get('ns');
        const data = JSON.parse(payload);
        return ns ? data[ns] : data;
      },
      requestOptions: {
        mode: 'cors',
        credentials: 'omit'
      }
    },
    interpolation: {
      escapeValue: false
    }
  });
