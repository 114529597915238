import { FC } from 'react';
import cn from 'classnames';
import styles from './Menu.module.scss';
import { useTranslation } from 'react-i18next';
import { Contacts } from './Contacts';

const langs = [
  {
    id: 'en',
    label: '🇺🇸 English'
  },
  {
    id: 'uk',
    label: '🇺🇦 Українська'
  }
];

interface MenuProps {
  className?: string;
  onClose?: () => void;
}

export const Menu: FC<MenuProps> = ({
  className,
  onClose
}) => {
  const { i18n } = useTranslation();

  return (
    <div className={cn(styles.Menu, className)}>
      <div className={styles.Content}>
        <div className={styles.Langs}>
          {langs.map(lang => (
            <button
              key={lang.id}
              type="button"
              className={styles.LangBtn}
              onClick={() => {
                i18n.changeLanguage(lang.id);
                onClose?.();
              }}
            >
              {lang.label}
            </button>
          ))}
        </div>
        <Contacts className={styles.Contacts} />
      </div>
    </div>
  );
};
