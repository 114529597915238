import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Contacts.module.scss';

interface ContactsProps {
  className?: string;
}

export const Contacts: FC<ContactsProps> = ({
  className
}) => {
  const [contacts, setContacts] = useState<ContactResponse[]>([]);

  useEffect(() => {
    const abort = new AbortController();
    async function loadContacts() {
      const res = await fetch('/data/contacts.json', {
        signal: abort.signal
      });
      const data: ContactsResponse = await res.json();
      setContacts(data.contacts);
    }
    loadContacts();
    return () => {
      abort.abort();
    };
  }, []);

  return (
    <div className={cn(styles.Contacts, className)}>
      {contacts.map(c => (
        <a
          key={c.id}
          href={c.url}
          target="_blank"
          rel="noreferrer"
          title={c.title}
          className={styles.Link}
        >
          {c.label}
        </a>
      ))}
    </div>
  );
}

interface ContactsResponse {
  contacts: ContactResponse[];
}

interface ContactResponse {
  id: string;
  label: string;
  title: string;
  url: string;
}
