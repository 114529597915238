import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import styles from './App.module.scss';
import { Home } from './Home/Home';
import { ResultCallback } from './content/Result/ResultCallback';
import { ResultFailure } from './content/Result/ResultFailure';
import { ResultSuccess } from './content/Result/ResultSuccess';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';

export const App: FC = () => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!container) return;
    const handleResize = () => {
      document.body.style.setProperty('--ref', `${container.clientWidth}px`);
    };
    const observer = new ResizeObserver(handleResize);
    observer.observe(container);
    return () => {
      observer.disconnect();
    };
  }, [container]);

  useEffect(() => {
    if (!container) return;
    const handleScroll = () => {
      container.style.setProperty('--scroll-amount', `${(container.scrollTop / window.innerHeight).toFixed(3)}`);
    };
    container.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [container]);

  return (
    <div ref={setContainer} className={styles.App}>
      <Routes>
        <Route path="" element={
          <>
            <Header className={clsx(styles.Header, styles.Scrolled)} />
            <Outlet />
            <Footer className={styles.Footer} />
          </>
        }>
          <Route path="" element={<Home />} />
        </Route>
        <Route path="result">
          <Route path="" element={<ResultCallback />} />
          <Route path="success" element={<ResultSuccess />} />
          <Route path="failure" element={<ResultFailure />} />
        </Route>
      </Routes>
    </div>
  );
};
