import { ZodType, z } from 'zod';

export interface OkResultState {
  status: 'ok';
  orderId: string;
  course: string;
  email: string;
}

export interface ErrResultState {
  status: 'err';
  orderId?: string;
  message?: string;
}

export type ResultState =
  | OkResultState
  | ErrResultState;

export const OkResultStateSchema: ZodType<OkResultState> = z.object({
  status: z.literal('ok'),
  orderId: z.string(),
  course: z.string(),
  email: z.string()
});

export const ErrResultStateSchema: ZodType<ErrResultState> = z.object({
  status: z.literal('err'),
  orderId: z.string().optional(),
  message: z.string()
});
